import { render, staticRenderFns } from "./indexBarthel.vue?vue&type=template&id=08c1fa6a"
import script from "./indexBarthel.vue?vue&type=script&lang=js"
export * from "./indexBarthel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports