<template>
  <div>
    <div class="row mt-2">
      <span class="font-weight-bolder">Indice de Barthel</span>
      <table class="table table-bordered mt-2">
        <tr>
          <th>Aspecto a evaluar</th>
          <th></th>
        </tr>
        <tr v-for="item in instrumentos" :key="item.id">
          <th>{{ item.pregunta }}</th>
          <th>
            <select class="form-control" v-model="item.id_opcion">
              <option value="">--seleccione una opcion--</option>
              <option
                :value="opc.id"
                v-for="opc in item.opciones"
                :key="opc.id"
              >
                {{ opc.respuesta }}
                ( = {{ opc.puntuacion }} )
              </option>
            </select>
          </th>
        </tr>
      </table>
    </div>
    <div
      class="alert alert-danger text-center"
      v-if="$v.instrumentos.$error && $v.instrumentos.$dirty"
    >
      Debe completar el formulario
    </div>
  </div>
</template>
<script>
import instrumentoService from "../../../../services/instrumentoService";
import historiaInstrumentoService from "../../../../services/historiaInstrumentoService";
import Toast from "../../../../components/common/utilities/toast";
import { isEmpty } from "lodash";

import { required } from "vuelidate/lib/validators";
export default {
  props: ["idHistoria", "finalizada"],
  data() {
    return {
      instrumentos: [],
      id_instrumento: "",
      codigo: "barthel",
      hasStore: false,
      id_instrumento_historia: "",
    };
  },
  validations() {
    return {
      instrumentos: {
        $each: {
          id_opcion: { required },
        },
      },
    };
  },
  methods: {
    async cargarInstrumentos() {
      const response = await instrumentoService.showByCodigo(this.codigo);
      this.id_instrumento = response.data.id;
      this.instrumentos = response.data.preguntas.map((pregunta) => {
        return {
          ...pregunta,
          id_opcion: "",
        };
      });
    },
    async save() {
      try {
        this.$v.$touch();

        if (this.$v.$invalid) return;
        let obj = {
          ...(this.hasStore
            ? { id_instrumento_historia: this.id_instrumento_historia }
            : {}),
          id_historia: this.idHistoria,
          id_instrumento: this.id_instrumento,
          puntuacion_total: this.puntuacion_total,
          respuestas: this.instrumentos.map((x) => {
            let puntaje =
              x.opciones.find((y) => y.id === parseInt(x.id_opcion))
                ?.puntuacion || 0;
            return {
              ...x,
              puntaje: puntaje,
            };
          }),
        };

        //console.log(obj);

        this.LoaderSpinnerShow();
        if (this.hasStore) {
          await historiaInstrumentoService.update(obj);
          const response_historia = await historiaInstrumentoService.showByHcI(
            this.idHistoria,
            this.id_instrumento
          );
          this.id_instrumento_historia = response_historia.data.id;
          this.hasStore = true;
        } else {
          await historiaInstrumentoService.store(obj);
          const response_historia = await historiaInstrumentoService.showByHcI(
            this.idHistoria,
            this.id_instrumento
          );
          this.id_instrumento_historia = response_historia.data.id;
          this.hasStore = true;
        }
        this.LoaderSpinnerHide();

        Toast.fire({
          icon: "success",
          title: "Datos guardados con exito",
        });
      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: "error",
          title: "Ocurrio un error al procesar la solicitud",
        });
      }
    },
  },
  async created() {
    await this.cargarInstrumentos();

    if (this.finalizada === "N") {
      const response_historia = await historiaInstrumentoService.showByHcI(
        this.idHistoria,
        this.id_instrumento
      );
      if (!isEmpty(response_historia.data)) {
        this.hasStore = true;
        this.id_instrumento_historia = response_historia.data.id;
        this.instrumentos.forEach((pregunta, i) => {
          let id_opcion =
            response_historia.data.detalles.find(
              (x) => x.opcion.id_pregunta === pregunta.id
            )?.id_opcion || "";
          console.log(id_opcion, i);
          this.instrumentos[i].id_opcion = id_opcion;
        });
      }
    }
  },
  computed: {
    puntuacion_total: function () {
      return this.instrumentos.reduce((t, c) => {
        let puntaje =
          c.opciones.find((x) => x.id === parseInt(c.id_opcion))?.puntuacion ||
          0;
        return t + puntaje;
      }, 0);
    },
  },
};
</script>
